import React, { useState } from 'react';

import pse from '../../img/pse-forma_clipdrop-background-removal.png';

const Donaciones = () => {

    const [mov, setMov] = useState(-110);

    return (
        <div className="fixed-unfollow-links" style={{ right: `${mov}px` }} onMouseEnter={() => setMov(0)} onMouseLeave={() => setMov(-110)}>
            <img src={pse} style={{width: "90px", borderRadius: "20px 0 0 20px"}}/><p style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", margin: "0px", color: "white"}}>Realiza tu donación aquí</p>
            {/* <a href='https://instagram.com/vlzbuild?igshid=MTIyMzRjYmRlZg==' target='_blank' style={{color: "white"}}><i className="fa-brands fa-instagram fa-3x redes-item"></i></a>
            <a href='https://www.linkedin.com/company/vlz-build/' target='_blank' style={{color: "white"}}><i className="fa-brands fa-linkedin fa-3x redes-item"></i></a>
            <i className="fa-solid fa-at fa-3x redes-item"></i> */}
        </div>
    );
}

export default Donaciones;